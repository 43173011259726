import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toDataSourceRequestString, toODataString } from '@progress/kendo-data-query';
import { delay, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/assets/config/app-settings';

export abstract class GridService extends BehaviorSubject<GridDataResult> {
  public loading!: boolean;

  constructor(public http: HttpClient, protected collectionName: string, public settings: AppSettings) {
    super({
      data: [],
      total: 0,
    });
  }

  public query(state: any, userId: any): void {
    this.fetch(this.collectionName, state, userId).subscribe((x) => super.next(x));
  }

  protected fetch(collectionName: string, state: any, userId: any): Observable<GridDataResult> {
    const queryStr = `${toDataSourceRequestString(state)}`;

    this.loading = true;

    // this.relativeUrl = `/${this.relativeUrl}`;
    return this.http.get(`${this.settings.apiBaseUrl}/api/${collectionName}/gets/${userId}?${queryStr}`).pipe(
      map(
        (response: any) =>
          <GridDataResult>{
            data: response.data,
            total: parseInt(response.count, 10),
          }
      ),
      delay(500),
      tap(() => (this.loading = false))
    );
  }
}

@Injectable()
export class InvoicesService extends GridService {
  constructor(http: HttpClient, settings: AppSettings) {
    super(http, 'invoice', settings);
  }

  queryAll(st?: any): Observable<GridDataResult> {
    const state = Object.assign({}, st);
    delete state.skip;
    delete state.take;

    return this.fetch(this.collectionName, state, 'all');
  }

  finish(customerId: string, userId: string, numberId: string, timeZone: string) {
    return this.http
      .post<{ user: any, number: any, customer: any }>(`${this.settings.apiBaseUrl}/api/invoice/finish`, {
        customerId,
        userId,
        numberId,
        timeZone
      })
  }
}
