import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ODataService } from 'src/app/dashboard/services/odata.service';
import { AppSettings } from 'src/assets/config/app-settings';

@Injectable({
  providedIn: 'root'
})
export class InvoiceGridService extends ODataService {
  constructor(http: HttpClient, settings: AppSettings) {
    const url = `${settings.apiBaseUrl}/api/invoice/gets`;
    super(http, url);
    this.mapper = (data: any[]) => {
      data.map((x) => {
        // x.createdAt = new Date(x.createdAt)
      });
      return data;
    }
  }
}
