import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoicesRoutingModule } from './invoices-routing.module';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InvoicesService } from '../core/services/invoices.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InvoiceGridService } from './services/invoice-grid.service';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';

@NgModule({ declarations: [
        InvoiceListComponent
    ], imports: [CommonModule,
        InvoicesRoutingModule,
        IndicatorsModule,
        GridModule,
        ButtonsModule,
        LayoutModule,
        PDFViewerModule], providers: [
        InvoicesService,
        InvoiceGridService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class InvoicesModule { }
