import { saveAs } from '@progress/kendo-file-saver';
import { detectDesktopBrowser, detectMobileOS } from '@progress/kendo-common';
import { getDocument, renderTextLayer } from 'pdfjs-dist';
const MAX_CANVAS_WIDTH_HEIGHT_CHROME = 65535;
const MAX_CANVAS_AREA_CHROME_SAFARI = 268435456;
const MAX_CANVAS_WIDTH_HEIGHT_FIREFOX = 32767;
const MAX_CANVAS_AREA_FIREFOX = 124992400;
const MAX_CANVAS_WIDTH_SAFARI = 4194303;
const MAX_CANVAS_HEIGHT_SAFARI = 8388607;
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /Macintosh/i.test(navigator.userAgent);
const isSafari = userAgent => {
  return detectDesktopBrowser(userAgent).safari || detectMobileOS(userAgent) && detectMobileOS(userAgent).browser === 'mobilesafari';
};
const isFirefox = userAgent => {
  const desktopBrowser = detectDesktopBrowser(userAgent);
  const mobileOS = detectMobileOS(userAgent);
  return desktopBrowser && desktopBrowser.mozilla || mobileOS && mobileOS.browser === 'firefox';
};
/**
 * @hidden
 */
export const DEFAULT_ZOOM_LEVEL = 1.25;
const scale = () => isIOS() ? devicePixelRatio : devicePixelRatio * 3;
const parsePdfFromBase64String = base64String => {
  return atob(base64String.replace(/^(data:application\/pdf;base64,)/gi, ''));
};
const getDocumentParameters = options => {
  let params = {
    verbosity: 0,
    isEvalSupported: false
  };
  if (typeof options.data === 'string') {
    params.data = parsePdfFromBase64String(options.data);
  } else if (typeof options.url === 'string') {
    params.url = options.url;
  } else if (options.arrayBuffer instanceof ArrayBuffer) {
    params = options.arrayBuffer;
  } else if (options.typedArray) {
    params = options.typedArray;
  }
  return params;
};
/**
 * @hidden
 */
export const removeChildren = dom => {
  while (dom.firstChild) {
    dom.removeChild(dom.firstChild);
  }
};
const appendPage = (dom, page, index) => {
  if (index === 0) {
    removeChildren(dom);
  }
  dom.appendChild(page);
};
/**
 * @hidden
 */
export const download = (options, fileName = 'Document', saveOptions = {}, onDownload) => {
  if (options.pdf) {
    options.pdf.getData().then(data => new Blob([data], {
      type: 'application/pdf'
    })).then(blob => {
      if (!onDownload(blob, fileName, saveOptions)) {
        saveAs(blob, fileName, saveOptions);
      }
    }).catch(reason => {
      options.error.call(undefined, reason);
    });
  }
};
/**
 * @hidden
 */
export const loadPDF = options => {
  const params = getDocumentParameters(options);
  const {
    dom,
    zoom,
    done,
    error
  } = options;
  getDocument(params).promise.then(pdfDoc => {
    const pages = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      pages.push(pdfDoc.getPage(i));
    }
    return {
      pages,
      pdfDoc
    };
  }).then(({
    pages,
    pdfDoc
  }) => {
    Promise.all(pages).then(all => all.map((page, i) => {
      appendPage(dom, renderPage(page, zoom, error), i);
      return page;
    })).then(pdfPages => {
      done({
        pdfPages,
        pdfDoc,
        zoom
      });
    }).catch(reason => {
      options.error(reason);
    });
  }).catch(reason => {
    options.error(reason);
  });
};
/**
 * @hidden
 */
export const reloadDocument = params => {
  const {
    pdfDoc,
    zoom,
    dom,
    done,
    error
  } = params;
  const pages = [];
  for (let i = 1; i <= pdfDoc.numPages; i++) {
    pages.push(pdfDoc.getPage(i));
  }
  Promise.all(pages).then(all => all.map((page, i) => {
    appendPage(dom, renderPage(page, zoom, error), i);
    return page;
  })).then(done).catch(error);
};
/**
 * @hidden
 */
export const print = (pages, done, error) => {
  const dom = document.createElement('div');
  const allDone = pages.map(() => false);
  const scaleNum = scale();
  pages.forEach((page, index) => {
    const viewport = renderCanvas(page, el => {
      dom.appendChild(el);
      allDone[index] = true;
      if (allDone.every(Boolean)) {
        openPrintDialog(dom, Math.floor(viewport.width / scaleNum), Math.floor(viewport.height / scaleNum), done, error);
      }
    }, error);
  });
};
const openPrintDialog = (dom, width, height, done, onError) => {
  const printDialog = window.open('', '', 'innerWidth=' + width + ',innerHeight=' + height + 'location=no,titlebar=no,toolbar=no');
  if (!printDialog || !printDialog.document) {
    onError();
    return;
  }
  if (printDialog) {
    printDialog.document.body.style.margin = '0';
    printDialog.document.body.appendChild(dom);
    printDialog.focus();
    setTimeout(() => {
      printDialog.print();
      done();
    });
    const onAfterPrint = () => {
      printDialog.removeEventListener('afterprint', onAfterPrint);
      printDialog.close();
    };
    printDialog.addEventListener('afterprint', onAfterPrint);
  }
};
const renderCanvas = (page, done, error) => {
  const {
    canvasContext,
    viewport,
    scaleNum,
    canvas,
    pageElement
  } = createCanvas(page);
  page.render({
    canvasContext,
    viewport
  }).promise.then(() => {
    const printContent = new Image(Math.floor(viewport.width / scaleNum), Math.floor(viewport.height / scaleNum));
    printContent.src = canvas.toDataURL();
    const onload = () => {
      printContent.removeEventListener('load', onload);
      done(pageElement);
    };
    printContent.addEventListener('load', onload);
  }).catch(error);
  return viewport;
};
const createElement = function (name, className, styles) {
  const element = document.createElement(name);
  if (className) {
    element.className = className;
  }
  Object.keys(styles).forEach(key => element.style[key] = styles[key]);
  return element;
};
const transforms = {
  '0': '',
  '90': 'rotate(90deg) translateY(-100%)',
  '180': 'rotate(180deg) translate(-100%, -100%)',
  '270': 'rotate(270deg) translateX(-100%)'
};
const renderPage = (page, zoom, error) => {
  const {
    canvasContext,
    viewport,
    pageElement,
    styles
  } = createCanvas(page, zoom, 'k-page');
  page.render({
    canvasContext,
    viewport
  }).promise.then(() => {
    page.getTextContent().then(textContent => {
      const textLayer = createElement('div', 'k-text-layer', styles);
      renderTextLayer({
        textContentSource: textContent,
        container: textLayer,
        viewport: viewport,
        textDivs: []
      }).promise.then(() => {
        textLayer.style.width = textLayer.style.width.replace(/px/g, 'pt');
        textLayer.style.height = textLayer.style.height.replace(/px/g, 'pt');
        const rotation = textLayer.getAttribute('data-main-rotation') || '0';
        if (transforms[rotation]) {
          textLayer.style.transform = transforms[rotation];
          textLayer.style.transformOrigin = 'top left';
        }
        textLayer.querySelectorAll('span').forEach(el => {
          if (el.style.fontSize) {
            el.style.fontSize = el.style.fontSize.replace(/px/g, 'pt');
          }
        });
        pageElement.appendChild(textLayer);
      }).catch(error);
    });
  }).catch(error);
  return pageElement;
};
const searchMatchScrollLeftOffset = 0;
const searchMatchScrollTopOffset = -64;
const scrollToSearchMatch = (matchElement, ref) => {
  if (!matchElement) {
    return;
  }
  const closestCharElement = matchElement.closest('.k-text-char');
  const closestTextElement = closestCharElement ? closestCharElement.closest('span[role="presentation"]') : null;
  if (!closestTextElement) {
    return;
  }
  const closestPageElement = closestTextElement.closest('.k-page');
  if (!closestPageElement) {
    return;
  }
  const scrollLeft = closestPageElement.offsetLeft + -1 * ref.scroller.element.offsetLeft + closestTextElement.offsetLeft + searchMatchScrollLeftOffset;
  const scrollTop = closestPageElement.offsetTop + -1 * ref.scroller.element.offsetTop + closestTextElement.offsetTop + searchMatchScrollTopOffset;
  ref.scroller.scrollTo(scrollLeft, scrollTop, {
    trackScrollEvent: false
  });
};
/**
 * @hidden
 */
export const goToNextSearchMatch = ref => {
  ref.search.markNextMatch();
  const matchElement = ref.search.getActiveMatchElement();
  scrollToSearchMatch(matchElement, ref);
};
/**
 * @hidden
 */
export const goToPreviousSearchMatch = ref => {
  ref.search.markPreviousMatch();
  const matchElement = ref.search.getActiveMatchElement();
  scrollToSearchMatch(matchElement, ref);
};
/**
 * @hidden
 */
export const calculateZoomLevel = (zoomLevel, zoomLevelType, currentZoom, dom) => {
  const documentContainer = dom.closest('.k-pdf-viewer-canvas');
  const page = dom.querySelector('.k-page');
  const pageSize = {
    width: page.offsetWidth,
    height: page.offsetHeight
  };
  let calculatedZoomLevel = zoomLevel;
  if (zoomLevelType === 'ActualWidth') {
    calculatedZoomLevel = 1;
  } else if (zoomLevelType === 'FitToWidth') {
    calculatedZoomLevel = documentContainer.offsetWidth / (pageSize.width / currentZoom);
  } else if (zoomLevelType === 'FitToPage') {
    calculatedZoomLevel = documentContainer.offsetHeight / (pageSize.height / currentZoom);
  }
  return calculatedZoomLevel;
};
/**
 * Scrolls the PDFViewer document to the passed page number.
 *
 * @param rootElement The root HTML element of the PDFViewer component.
 * @param pageNumber The page number.
 */
export const scrollToPage = (rootElement, pageNumber) => {
  const pages = rootElement.querySelectorAll('.k-page');
  const page = pages[0];
  if (page instanceof HTMLDivElement) {
    const top = (page.offsetHeight + page.offsetTop) * Math.max(0, Math.min(pageNumber, pages.length - 1));
    const scrollElement = page.closest('.k-pdf-viewer-canvas');
    if (scrollElement) {
      scrollElement.scrollTo({
        top,
        behavior: 'auto'
      });
    }
  }
};
/**
 * A function which gives you the page number of the document according to the scroll position.
 *
 * @param rootElement The root HTML element of the PDFViewer component.
 * @returns The page number.
 */
export const currentPage = rootElement => {
  const scrollElement = rootElement.querySelector('.k-pdf-viewer-canvas');
  const page = rootElement.querySelector('.k-page');
  return scrollElement && page ? Math.floor(Math.round(scrollElement.scrollTop) / (page.offsetHeight + page.offsetTop) + 0.01) : 0;
};
/**
 * @hidden
 *
 * related to https://github.com/telerik/kendo-pdfviewer-common/issues/6
 * the bigger the canvas size, the worse the performance;
 * if initial size after scaling is greater than browser limits,
 * we are limiting it to the limits, then halving it for performance.
 */
const adjustCanvasSize = (targetWidth, targetHeight) => {
  const {
    maxWidth,
    maxHeight,
    maxArea
  } = isFirefox(navigator.userAgent) ? {
    maxWidth: MAX_CANVAS_WIDTH_HEIGHT_FIREFOX,
    maxHeight: MAX_CANVAS_WIDTH_HEIGHT_FIREFOX,
    maxArea: MAX_CANVAS_AREA_FIREFOX
  } : isSafari(navigator.userAgent) ? {
    maxWidth: MAX_CANVAS_WIDTH_SAFARI,
    maxHeight: MAX_CANVAS_HEIGHT_SAFARI,
    maxArea: MAX_CANVAS_AREA_CHROME_SAFARI
  } : {
    maxWidth: MAX_CANVAS_WIDTH_HEIGHT_CHROME,
    maxHeight: MAX_CANVAS_WIDTH_HEIGHT_CHROME,
    maxArea: MAX_CANVAS_AREA_CHROME_SAFARI
  };
  let adjustedWidth = targetWidth;
  let adjustedHeight = targetHeight;
  const ratio = targetWidth / targetHeight;
  if (targetWidth > maxWidth) {
    adjustedWidth = maxWidth;
    const deltaWidth = targetWidth - maxWidth;
    const deltaHeight = deltaWidth / ratio;
    adjustedHeight = targetHeight - deltaHeight;
  }
  if (adjustedHeight > maxHeight) {
    const deltaHeight = adjustedHeight - maxHeight;
    const deltaWidth = deltaHeight * ratio;
    adjustedHeight = maxHeight;
    adjustedWidth -= deltaWidth;
  }
  const adjustedArea = adjustedWidth * adjustedHeight;
  if (adjustedArea > maxArea) {
    const areaRatio = Math.sqrt(maxArea / adjustedArea);
    adjustedWidth *= areaRatio;
    adjustedHeight *= areaRatio;
  }
  const adjustRatio = adjustedWidth / targetWidth;
  return {
    adjustedWidth: adjustedWidth !== targetWidth ? Math.floor(adjustedWidth / 2) : targetWidth,
    adjustedHeight: adjustedHeight !== targetHeight ? Math.floor(adjustedHeight / 2) : targetHeight,
    adjustRatio: adjustRatio !== 1 ? adjustRatio / 2 : 1
  };
};
const createCanvas = (page, zoom = 1, cssClass = '') => {
  const scaleNum = scale();
  const viewport = page.getViewport({
    scale: scaleNum
  });
  const {
    adjustedWidth,
    adjustedHeight,
    adjustRatio
  } = adjustCanvasSize(viewport.width, viewport.height);
  const styles = {
    width: Math.floor(viewport.width / scaleNum) * zoom + 'pt',
    height: Math.floor(viewport.height / scaleNum) * zoom + 'pt'
  };
  const pageElement = createElement('div', cssClass, styles);
  const canvas = createElement('canvas', '', {
    width: '100%',
    height: '100%'
  });
  canvas.height = adjustedHeight;
  canvas.width = adjustedWidth;
  const canvasContext = canvas.getContext('2d');
  pageElement.appendChild(canvas);
  const adjustedScale = adjustRatio * scaleNum;
  viewport.width = adjustedWidth;
  viewport.height = adjustedHeight;
  viewport.scale = adjustedScale;
  return {
    canvasContext,
    viewport,
    scaleNum,
    canvas,
    pageElement,
    styles
  };
};