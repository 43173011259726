 import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { SessionService } from 'src/app/core/services/session.service';
import { DateTime } from 'luxon';
import { InvoiceGridService } from '../services/invoice-grid.service';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { AppSettings } from 'src/assets/config/app-settings';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  public invoicePdfUrl: string | undefined;
  public pdfLoading = false;
  private unsubscribe = new Subject<void>();

  constructor(
    private settings: AppSettings,
    public gridService: InvoiceGridService,
    private session: SessionService,
    public subscriptionsService: SubscriptionsService
  ) { }

  ngOnInit(): void {
    this.gridService.state.filter?.filters.push({
      field: 'customer',
      operator: 'eq',
      value: this.session.user?.customer?.stripeId!,
    });
    this.gridService.read(() => { 
      const lastIndex = this.gridService.gridDataResult!.data.length - 1;
      this.viewInvoiceClick(this.gridService.gridDataResult?.data[lastIndex].id);
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onStateChange(state: State): void {
    this.gridService.state = state;
    this.gridService.read(() => { 
      this.viewInvoiceClick(this.gridService.gridDataResult?.data[0].id);
    });
  }

  timestampToDate(timestamp: number): string {
    const cstDateTime = DateTime.fromSeconds(timestamp).setZone('America/Chicago');

    const month = this.pad(cstDateTime.month);
    const day = this.pad(cstDateTime.day);
    const year = cstDateTime.year;

    return `${month}/${day}/${year}`;
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : '' + number;
  }

  public viewInvoiceClick(invoiceId: string) {
    this.pdfLoading = true;
    const customerId = this.session.user?.customer?._id;
    this.invoicePdfUrl = `${this.settings.blobBaseUrl}/prd/${customerId}/invoices/${invoiceId}.pdf`;
    this.pdfLoading = false;
  }

  public formatCurrency(amount: number) {  
    amount /= 100;

    let formattedAmount = amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    return formattedAmount;
  }
}