<div class="container">
  <kendo-tabstrip class="r-tabs mt-8 shadow-sm">
    <kendo-tabstrip-tab [selected]="true">
      <ng-template kendoTabTitle>
        <div class="p-2">
          Monthly Invoices
          <em class="field-icon fs-3 fa-duotone fa-file-invoice px-1"></em>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <div class="p-4 mt-0">
          <kendo-grid [filterable]="false" [data]="gridService | async" [pageable]="true"
            [pageSize]="gridService.state.take" [skip]="gridService.state.skip" [(filter)]="gridService.state.filter"
            [loading]="this.gridService.loading" (dataStateChange)="onStateChange($event)">
            <kendo-grid-column field="data.object.created" title="Created">
              <ng-template kendoGridHeaderTemplate>
                Created&nbsp;<i class="fa-duotone fa-calendar-days"></i>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ timestampToDate(dataItem.created) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="invoice_pdf" title="View PDF">
              <ng-template kendoGridHeaderTemplate>
                View Invoice&nbsp;<i class="fa-light fa-file-pdf"></i>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <a style="cursor: pointer" (click)="viewInvoiceClick(dataItem.id)"
                class="link-underline-primary">View PDF</a>&nbsp;&nbsp;<i
              class="fa-duotone fa-arrow-down-to-line"></i>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="invoice_pdf" title="Download PDF">
            <ng-template kendoGridHeaderTemplate>
              Download Invoice&nbsp;<i class="fa-light fa-file-pdf"></i>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <a class="link-underline-primary" href="{{ dataItem.invoice_pdf }}" target="_blank">Download
                PDF</a>&nbsp;&nbsp;<i class="fa-duotone fa-arrow-down-to-line"></i>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="status" title="Status">
              <ng-template kendoGridHeaderTemplate>
                Status&nbsp;<i class="fa-duotone fa-spinner-third"></i>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="badge bg-primary">{{ dataItem.status | uppercase }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="subtotal" title="Subtotal">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ formatCurrency(dataItem.subtotal) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tax" title="Tax">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ formatCurrency(dataItem.tax) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="total" title="Total">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ formatCurrency(dataItem.total) }}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  @if (pdfLoading) {
    <div class="d-flex justify-content-center mt-10 mb-10">
      <kendo-loader type="pulsing" themeColor="primary" size="medium"> </kendo-loader>
    </div>
  }
  <kendo-pdfviewer style="height: 11n" [url]="invoicePdfUrl" [zoom]="1" class="mt-10"> </kendo-pdfviewer>
</div>